import React from 'react';
import {connect} from "react-redux";
import AppLayout from "../components/AppLayout";

import { submitIpcRequest } from "../store/actions/consoleActions";
import DocumentsInfoPanel from "../components/DocumentsInfoPanel";
import ReportsPanel from "../components/ReportsPanel";


function DocumentsPage(props) {
    return (
        <AppLayout title="Documents">
            <DocumentsInfoPanel submitRequest={props.submitIpcRequest}/>
            <ReportsPanel submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest})(
    DocumentsPage
);
