import React from "react";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {FormInputCtr} from "./FormInputCtr";

import useApiRequest from "../hooks/useApiRequest";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import Alert from "./Alert";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        initValue: '',
        rules: {
            required: 'Merchant ID required'
        },
        hide: () => false
    },
    "action": {
        label: 'Outcome',
        type: 'select',
        data: [
            {
                id: 'Approved'
            },
            {
                id: 'Denied'
            },
            {
                id: 'Suspended'
            },
            {
                id: 'Deactivated'
            }
        ],
        initValue: 'Approved',
        rules: {
            required: 'Outcome required'
        },
        hide: () => false
    },
    "communications": {
        label: 'Communications',
        type: 'select',
        data: [
            {
                id: 'Yes'
            },
            {
                id: 'No'
            }
        ],
        initValue: 'Yes',
        rules: {
            required: 'Communications required'
        },
        hide: (action) => action === 'Approved' || action === 'Denied'
    },
    "reasons": {
        label: 'Reasons',
        initValue: "TEST",
        rules: {
            required: 'Reasons required'
        },
        hide: () => false
    },
    "author": {
        label: 'Reviewer',
        initValue: "test@wisetack.com",
        rules: {
            required: 'Reviewer required'
        },
        hide: () => false
    },
}

const fieldList = [
    'merchantId',
    'action',
    'communications',
    'reasons',
    'author'
]

const requestType = 'review-merchant-lambda';

export default function MerchantReviewPanel({submitRequest}) {
    const reviewMerchantResult = useSelector(state => state.console.reviewMerchantResult);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const merchantReviewed = useApiRequestExecuted('reviewMerchantRequestId', requestId);

    const { handleSubmit, control, resetField, watch } = useForm({
        defaultValues: {
            ...formsData
        }
    });
    const watchAction = watch("action");

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const MerchantReviewed = () => {
        if (merchantReviewed && reviewMerchantResult) {
            return <Alert severity="success">{JSON.stringify(reviewMerchantResult)}</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Merchant Signup Review" tooltip="Merchant signup review for testing">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                            hide={formFields[name].hide(watchAction)}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <MerchantReviewed/>
            </Grid>
        </ExPanel>
    )
}