import React from "react";
import {useSelector} from "react-redux";
import csvToJson from "convert-csv-to-json";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import ExPanel from "./ExPanel";
import useApiRequest from "../hooks/useApiRequest";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const requestType = 'decrypt-report-file';
const contentRequestType = 'document-content';

const convertCSVtoJSON = (csvContent) => {
    return csvToJson.fieldDelimiter(';').csvStringToJson(csvContent.replaceAll(';',',').replaceAll('","','";"').replaceAll('"',''));
}

const flatViewStyle = {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '30px'
}

export default function ReportDialog({data, reportToken, documents, onClose, submitRequest}) {
    const reportFileInfo = useSelector(state => state.console.reportFileInfo);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const dataPresent = !!reportFileInfo && !!reportToken && reportToken === reportFileInfo.reportToken;
    const dataToConvert = dataPresent ? reportFileInfo.content : null;

    const [csvView, setCsvView] = React.useState(false);
    const [jsonContent, setJsonContent] = React.useState(false);

    const handleOnReload = () => {
        if (reportToken) {
            submitRequest(requestType, {reportToken}, newRequest());
        }
    }

    React.useEffect(() => {
        handleOnReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (dataToConvert) {
            setJsonContent(convertCSVtoJSON(dataToConvert))
        } else {
            setJsonContent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToConvert]);

    const handleCsvViewChange = () => {
        setCsvView(!csvView);
    }

    const handleOpenDocument = (id) => {
        submitRequest(contentRequestType, {id, open: true}, newRequest());
    }

    const CSVSwitch = () => {
        if (!data) {
            return null;
        }
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={csvView}
                        onChange={handleCsvViewChange}
                        name="CSV"
                        color="primary"
                    />
                }
                label="CSV View"
            />
        )
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Progress show={requestInProgress} requestId={requestId}/>
                Token <CopyToClipboard text={reportToken}>
                <Chip label={reportToken} size="small" color="primary" avatar={<Avatar>T</Avatar>} onClick={handleOnReload}/>
            </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                { documents && !!documents.length &&
                <ExPanel title="Documents">
                    <Grid item xs={12}>
                        { documents.map(item =>
                            <Chip
                                key={item} size="small" color="default" avatar={<Avatar>D</Avatar>} label={item}
                                onClick={() => handleOpenDocument(item)}
                            />
                        )}
                    </Grid>
                </ExPanel>
                }
                <ExPanel title="CSV Data">
                    <Grid item xs={12}>
                        <CSVSwitch/>
                        {csvView && dataToConvert &&
                        <pre style={flatViewStyle}>{dataToConvert}</pre>
                        }
                        {!csvView &&
                        <JsonView hide={!jsonContent} data={jsonContent} shouldExpandNode={(keyPath, data, level) => level < 3}/>
                        }
                    </Grid>
                </ExPanel>
                <ExPanel title="Data Record">
                    <Grid item xs={12}>
                        <JsonView data={data} shouldExpandNode={(keyPath, data, level) => level < 1}/>
                    </Grid>
                </ExPanel>
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

