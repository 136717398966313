import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import {Button, DialogActions, Grid} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";

import ExPanel from "./ExPanel";
import {FormInputCtr} from "./FormInputCtr";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";
import JsonSwitch from "./JsonSwitch";
import {phoneNumberPattern} from "../utils/validators";
import {getFieldList} from "../utils/format";
import useApiRequest from "../hooks/useApiRequest";

const formFields = {
    "phoneNumber": {
        label: 'Phone',
        initValue: '',
        type: 'phone',
        rules: {
            pattern: phoneNumberPattern
        },
        order: 1
    },
    "merchantId": {
        label: 'Merchant ID',
        initValue: '',
        order: 2
    },
    "active": {
        label: 'Status',
        type: 'select',
        data: [
            {id: "ALL"},
            {id: "ACTIVE"},
            {id: "IDLE"},
        ],
        initValue: 'ALL',
        order: 3
    },
    "limit": {
        label: 'Limit',
        initValue: '100',
        order: 4
    }
}

const fieldList = getFieldList(formFields)

const requestType = 'loans-list';

const Loans = ({columns, loading, jsonView, loans}) => {
    if (!loans) {
        return null;
    }
    if (jsonView) {
        return (
            <JsonView data={loans} shouldExpandNode={(keyPath, data, level) => level < 1}/>
        )
    }
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            getRowId={(row) => row.id}
            rows={loans}
            hideFooterSelectedRowCount={true}
            autoPageSize={true}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

export default function LoanListPanel({submitRequest}) {
    const loanListInfo = useSelector(state => state.console.loanListInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [jsonView, setJsonView] = React.useState(false);

    const dataPresent = !!loanListInfo && loanListInfo.applications;

    const { handleSubmit, control, resetField } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    const copyCellRenderer = ({value}) => {
        return <CopyToClipboard text={value}>
            <div>{value}</div>
        </CopyToClipboard>
    }

    const merchantIdCellRenderer = ({row}) => {
        if (row.merchantInformation) {
            return copyCellRenderer({value: row.merchantInformation.id});
        }
        return '';
    }

    const columns = [
        {field: 'createdAt', headerName: 'Created', width: 180},
        {field: 'id', headerName: 'loan ID', width: 310, renderCell: copyCellRenderer},
        {field: 'merchantId', headerName: 'merchant ID', width: 310, renderCell: merchantIdCellRenderer},
        {field: 'status', headerName: 'App Status', width: 160},
    ]

    return (
        <ExPanel title="Loan List">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                </form>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                {dataPresent &&
                <Loans
                    loans={loanListInfo.applications}
                    jsonView={jsonView}
                    loading={requestInProgress}
                    columns={columns}
                />
                }
            </Grid>
        </ExPanel>
    )
}